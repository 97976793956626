import Client1 from '../../assets/image/saas/testimonial/client-1.jpg';
import Client2 from '../../assets/image/agency/client/denny.png';

export const Faq = [
  {
    id: 1,
    expend: true,
    title:
      "L'outil de Business Plan est-il utilisable sans formation comptable ?\n",
    description:
      'Oui. StartMyStory est destiné à des entrepreneurs n’ayant pas ou peu de connaissances comptables. \n' +
      'Il est un outil d’aide à la prise de décision par rapport à des objectifs chiffrés. Vous êtes accompagné tout au long de votre projet par une check-list des principaux postes et règles comptables et fiscales dans la partie saisie.\n' +
      'Les Tableaux de bord sont simples, facilement compréhensibles et permettent un accès direct aux modifications que vous souhaitez apporter.',
  },
  {
    id: 2,
    title:
      'Pour quels types de projets est-il nécessaire de réaliser un business plan ?',
    description:
      'Créateur, Auto-entrepreneur, micro-entreprise ou TPE/PME établie, vous avez besoin de réaliser un prévisionnel de votre activité, voire d\'une "business unit" en développement.\n' +
      'Même si vous n’avez pas l’intention d’emprunter de l’argent ou de faire appel à des partenaires investisseurs, le business plan orientera votre réflexion sur les différents enjeux financiers et organisationnels de votre projet. Le business plan n’est pas seulement un document destiné à votre banquier, il est aussi pour vous un outil de pilotage de votre activité.',
  },
  {
    id: 3,
    title: 'Combien de temps faut-il pour réaliser un business plan ?',
    description:
      'Si vous bénéficiez de toutes les informations nécessaires à la construction d’un business plan, le remplir ne prendra que quelques heures. Il est cependant assez rare d’avoir toutes ces informations en même temps. Avec StartMyStory vous pouvez accéder à votre business plan en ligne autant de fois que vous le voulez et quand vous voulez. Vous avez le temps de construire votre business plan étape par étape, à votre rythme.\n' +
      "Dans sa version Entrepreneur, vous pouvez faire vivre et modifier vos prévisions avec la réalisation des mois passés afin de disposer en temps réel d'un outil de suivi de votre activité.",
  },
  {
    id: 4,
    title:
      "Sur combien d'exercices comptable puis-je réaliser mon business plan ?",
    description:
      'Le business plan vous est proposé sur trois années d’exercice comptable par défaut. \n' +
      "Il n'est pas possible de choisir le nombre de mois pour son premier exercice de départ. Pour contourner cela, vous pouvez lisser votre exercice sur 12 mois, ou sur le nombre de mois nécessaires.\n",
  },
  {
    id: 5,
    title: 'Combien de business plan puis-je réaliser ?',
    description:
      'Vous pouvez créer un seul business plan avec un compte client StartMyStory. Pour en créer un nouveau, vous devez créer un autre compte client avec un autre identifiant Facebook ou LinkedIn Connect que le premier.\n',
  },
  {
    id: 6,
    title:
      'Sur quel niveau de précision puis-je travailler mon business plan ?',
    description:
      'StartMyStory vous permet de prévoir et suivre vos prévisions budgétaires au niveau mensuel. \n' +
      '\n' +
      'Oui. Grâce à StartMyStory vous pouvez visualiser votre business plan en ligne mensuellement et annuellement. Ceci pour chaque exercice.',
  },
  {
    id: 7,
    title: 'Comment rédiger le dossier de présentation de mon business plan ?',
    description:
      'StartMyStory prépare pour vous toutes les parties et sous-parties aidant à la rédaction du business plan. Et pour chacune des sous-parties StartMyStory vous propose un certains nombres de questions vous aidant à structurer votre projet. Vous êtes ainsi aidé dans la rédaction du document qui va accompagner la présentation des chiffres de votre activité. Un véritable business plan professionnel !',
  },
  {
    id: 8,
    title: 'Comment imprimer mon business-plan ?\n',
    description:
      'En version Créateur et Entrepreneur, vous pouvez imprimer votre business plan complet par le bouton « Télécharger mon Business Plan ». Le dossier se télécharge au format PDF. Une fois le document téléchargé sur votre ordinateur, vous pouvez l’imprimer ou le partager à votre convenance.',
  },
  {
    id: 9,
    title: 'Puis-je exporter mon business plan en format Excel ?',
    description:
      'Vous ne pouvez pas exporter les données de votre business plan. Vous pouvez néanmoins télécharger comme vous le désirez et autant de fois que nécessaire votre dossier PDF de présentation (données chiffrées, tableaux et graphiques, présentation du business plan).',
  },
  {
    id: 10,
    title: 'Est-ce que la confidentialité de mes données est assurée ?\n',
    description:
      "Nous prenons le plus grand soin à assurer la confidentialité et la sécurité des données. Nos serveurs sont des machines dédiées, sécurisées et gérées par des hébergeurs professionnels, et rompus aux questions de confidentialité et de sécurité. Les serveurs sont bien sûr protégés contre les coupures d'électricité et situés dans des salles où les accès sont rigoureusement contrôlés. Nous effectuons une sauvegarde de l'ensemble des données quotidiennement et vous garantissons un Temps d'Intervention de 2 heures en cas d'incident grave pour que nous puissions restaurer les données de la journée précédente. L’accès à vos données est protégé par un identifiant et un mot de passe cryptés. L'application est conforme au Règlement Général sur la Protection des Données nᵒ 2016/679.",
  },
  {
    id: 11,
    title: "Quel navigateur utiliser pour profiter de l'application?",
    description:
      "Il est recommandé d'utiliser le navigateur Google Chrome ou Mozilla Firefox pour utiliser StartMyStory. Il est aussi recommandé d'utiliser la dernière version de création de PDF pour pouvoir générer votre dossier final.\n",
  },
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-creative',
    title: '100% ENTREPRENEUR',
    description:
      'Choisissez l’offre qui correspond à votre profil d’entrepreneur.\n' +
      '\n' +
      'Une fois les premiers postes de prévisions de ventes ou de charges saisis, le tableau de bord de votre activité commence à donner vie à votre entreprise.\n' +
      '\n' +
      'Partagez-le tout aussi facilement avec les personnes de votre choix en toute confidentialité et sécurité.',
  },
  {
    id: 2,
    icon: 'flaticon-briefing',
    title: '100% GESTION',
    description:
      'Fini les calculs interminables dans des feuilles excel, les oublis ou les erreurs...\n' +
      '\n' +
      "Vous disposez d'une check-list des principaux postes de prévisions de votre entreprise, et pouvez en ajouter autant que vous souhaitez afin d’adapter votre business plan à votre métier.",
  },
  {
    id: 3,
    icon: 'flaticon-flask',
    title: '100% SUR-MESURE',
    description:
      '\n' +
      'Votre entreprise est unique… Votre business plan l’est également !\n' +
      '\n' +
      'StartMyStory vous permet de personnaliser très facilement tous les postes de votre activité, actuelle ou future.\n' +
      '\n' +
      'Faites vivre votre Business Plan avec autant d’agilité que l’est votre entreprise.',
  },
];

export const Footer_Data = [
  {
    title: 'Business Plan',
    menuItems: [
      {
        url: 'https://app.startmystory.fr',
        text: 'Me connecter',
      },
      {
        url: 'https://app.startmystory.fr',
        text: "S'inscrire",
      },
    ],
  },
  {
    title: 'Légales',
    menuItems: [
      {
        url: 'https://www.startmystory.fr/mentions-legales/',
        text: 'Mentions légales',
      },
      {
        url:
          'https://www.startmystory.fr/conditions-generales-de-vente-dutilisation/',
        text: 'CGU',
      },
    ],
  },
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-briefing',
    title: 'Saisie intuitive des prévisions',
    description:
      'Nous vous proposons par défaut les postes comptables incontournables d’une entreprise ; et calculons pour vous les différentes cotisations et règles comptables.',
  },
  {
    id: 2,
    icon: 'flaticon-trophy',
    title: 'Ajout de postes personnalisés',
    description:
      'À tout moment vous pouvez ajouter ou supprimer de nouveaux postes pour personnaliser entièrement votre business plan à votre activité.',
  },
  {
    id: 3,
    icon: 'flaticon-atom',
    title: 'Indicateurs mis à jour en temps réel',
    description:
      'Consultez en temps réel les impacts financiers de vos choix de gestion. Vous disposez sur la même page de travail de tous les indicateurs de votre activité.',
  },
  {
    id: 4,
    icon: 'flaticon-ruler',
    title: "Tableau de bord d'activité",
    description:
      'Au fil de la saisie de vos prévisions, le tableau de bord de votre activité se construit et vous aide dans votre prise de décision et d’arbitrage budgétaire.',
  },
  {
    id: 5,
    icon: 'flaticon-creative',
    title: 'Personnalisation de votre profil',
    description:
      'Vous pouvez personnaliser votre profil, paramétrer les préférences comptables de votre dossier ; ainsi que les statuts de cotisation de vos futurs salariés et vous-même.',
  },
  {
    id: 6,
    icon: 'flaticon-conversation',
    title: 'Données 100% sécurisées',
    description:
      'Authentification Facebook ou LinkedIn. Les données de votre Business Plan sont cryptées, et couvertes par la protection des données personnelles RGPD.',
  },
];

export const Timeline = [
  {
    title: 'Je crée mon compte',
    description:
      "Facebook ou LinkedIn Connect pour plus de simplicité et de sécurité. Procédez au paramétrage de vos règles comptables... Et c'est parti !",
  },
  {
    title: 'Je complète mes prévisions ',
    description:
      'A partir de la liste des postes existants, complétez sur 3 exercices vos futures Ventes, Charges, Immobilisations, Achats, ou Frais de Personnel',
  },
  {
    title: ' Je consulte mon Tableau de Bord',
    description:
      'Suivi de Trésorerie, Compte de Résultat ou Bilan prévisionnel... Faites évoluer vos prévisions budgétaires en fonction de vos objectifs de performance.',
  },
];

export const Testimonial = [
  {
    id: 1,
    name: 'Michal Andry',
    designation: 'Ceo of Invission Co.',
    comment:
      'Love to work with this designer in every our future project to ensure we are going to build best prototyping features.',
    avatar_url: Client1,
    social_icon: 'flaticon-instagram',
  },
  {
    id: 2,
    name: 'Roman Ul Oman',
    designation: 'Co-founder of QatarDiaries',
    comment:
      'Impressed with master class support of the team and really look forward for the future. A true passionate team.',
    avatar_url: Client2,
    social_icon: 'flaticon-twitter-logo-silhouette',
  },
];

export const MENU_ITEMS = [
  {
    label: 'Accueil',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Services',
    path: '#service_section',
    offset: '70',
  },
  {
    label: 'Prix',
    path: '#pricing_section',
    offset: '70',
  },
  {
    label: 'Fonctionnalités',
    path: '#feature_section',
    offset: '70',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '70',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    name: 'StartMyStory Starter',
    description:
      'Le pack de démarrage de votre future entreprise. Pour débuter en toute sérénité.',
    price: '9€',
    priceLabel: 'pour 1 mois',
    buttonLabel: "c'est parti !",
    url: 'https://app.startmystory.fr',
    listItems: [
      {
        content: 'Version Micro-Entreprise',
        dispo: true,
      },
      {
        content: 'Période de saisie des prévisions : 1 exercice',
        dispo: true,
      },
      {
        content: 'Personnalisation des postes de prévisions',
        dispo: false,
      },
      {
        content: 'Personnalisation des statuts salariés',
        dispo: false,
      },
      {
        content: 'Dossier de présentation du business plan',
        dispo: false,
      },
      {
        content: "Tableau de bord de pilotage de l'activité ",
        dispo: false,
      },
    ],
  },
  {
    name: 'StartMyStory Créateur',
    description:
      'Organisez vos postes de prévisions et imprimez votre business plan sur 3 exercices.',
    price: '19€',
    priceLabel: 'pour 1 mois',
    buttonLabel: "C'est parti !",
    url: 'https://app.startmystory.fr',
    listItems: [
      {
        content: 'Version Entreprise',
        dispo: true,
      },
      {
        content: 'Période de saisie des prévisions : 3 exercices',
        dispo: true,
      },
      {
        content: 'Personnalisation des postes de prévisions',
        dispo: true,
      },
      {
        content: 'Personnalisation des statuts salariés',
        dispo: true,
      },
      {
        content: 'Dossier de présentation du business plan',
        dispo: true,
      },
      {
        content: "Tableau de bord de pilotage de l'activité",
        dispo: false,
      },
    ],
  },
  {
    name: 'StartMyStory Entrepreneur',
    description:
      'Profitez à 100% de StartMyStory, pour assurer le suivi complet de votre entreprise au fil des mois.',
    price: '29€',
    priceLabel: 'pour 1 mois',
    buttonLabel: "C'est parti !",
    url: 'https://app.startmystory.fr',
    listItems: [
      {
        content: 'Version Entreprise',
        dispo: true,
      },
      {
        content: 'Période de saisie des prévisions : 3 exercices',
        dispo: true,
      },
      {
        content: 'Personnalisation des postes de prévisions',
        dispo: true,
      },
      {
        content: 'Personnalisation des statuts salariés',
        dispo: true,
      },
      {
        content: 'Dossier de présentation du business plan',
        dispo: true,
      },
      {
        content: "Tableau de bord de pilotage de l'activité",
        dispo: true,
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    name: 'StartMyStory Starter',
    description:
      'Le pack de démarrage de votre future entreprise. Pour débuter en toute sérénité.',
    price: '39€',
    priceLabel: 'pour 1 an',
    buttonLabel: "c'est parti !",
    url: 'https://app.startmystory.fr',
    listItems: [
      {
        content: 'Version Micro-Entreprise',
        dispo: true,
      },
      {
        content: 'Période de saisie des prévisions : 1 exercice',
        dispo: true,
      },
      {
        content: 'Personnalisation des postes de prévisions',
        dispo: false,
      },
      {
        content: 'Personnalisation des statuts salariés',
        dispo: false,
      },
      {
        content: 'Dossier de présentation du business plan',
        dispo: false,
      },
      {
        content: "Tableau de bord de pilotage de l'activité ",
        dispo: false,
      },
    ],
  },
  {
    name: 'StartMyStory Créateur',
    description:
      'Organisez vos postes de prévisions et imprimez votre business plan sur 3 exercices.',
    price: '69€',
    priceLabel: 'pour 1 an',
    buttonLabel: "C'est parti !",
    url: 'https://app.startmystory.fr',
    listItems: [
      {
        content: 'Version Entreprise',
        dispo: true,
      },
      {
        content: 'Période de saisie des prévisions : 3 exercices',
        dispo: true,
      },
      {
        content: 'Personnalisation des postes de prévisions',
        dispo: true,
      },
      {
        content: 'Personnalisation des statuts salariés',
        dispo: true,
      },
      {
        content: 'Dossier de présentation du business plan',
        dispo: true,
      },
      {
        content: "Tableau de bord de pilotage de l'activité",
        dispo: false,
      },
    ],
  },
  {
    name: 'StartMyStory Entrepreneur',
    description:
      'Profitez à 100% de StartMyStory, pour assurer le suivi complet de votre entreprise au fil des mois.',
    price: '99€',
    priceLabel: 'pour 1 an',
    buttonLabel: "C'est parti !",
    url: 'https://app.startmystory.fr',
    listItems: [
      {
        content: 'Version Entreprise',
        dispo: true,
      },
      {
        content: 'Période de saisie des prévisions : 3 exercices',
        dispo: true,
      },
      {
        content: 'Personnalisation des postes de prévisions',
        dispo: true,
      },
      {
        content: 'Personnalisation des statuts salariés',
        dispo: true,
      },
      {
        content: 'Dossier de présentation du business plan',
        dispo: true,
      },
      {
        content: "Tableau de bord de pilotage de l'activité",
        dispo: true,
      },
    ],
  },
];
